export interface Metadata {
	title: string;
	headline?: string;
	tags: string[];
	date: string;
}

export interface Post {
	path: string;
	metadata: Metadata;
	default: string;
}

type GlobResult = Record<
	string,
	{
		metadata: Metadata;
		default: string;
	}
>;

const normalizePath = (path: string): string => {
	return path.replace('./', '/').replace('.md', '').replace('.svx', '');
};

const getPosts = (allPosts: GlobResult): Post[] => {
	const results: Post[] = [];
	for (const path in allPosts) {
		const data = allPosts[path];
		results.push({
			path: normalizePath(path),
			...{
				metadata: data.metadata,
				default: data.default
			}
		});
	}
	return results;
};

const sorter = (postA: Post, postB: Post): number => {
	return new Date(postB.metadata.date).valueOf() - new Date(postA.metadata.date).valueOf();
};

const posts: Post[] = getPosts(import.meta.glob('./posts/*.{md,svx}', { eager: true }));

export const getSortedPosts = (limit: number | undefined = undefined): Post[] => {
	return posts.slice().sort(sorter).slice(0, limit);
};

export const getSortedPostsByTag = (tag: string): Post[] => {
	return getSortedPosts().filter((post) => post.metadata.tags.includes(tag));
};

export const getPostBySlug = (slug: string): Post => {
	return posts.find((post) => post.path.includes(slug.toLowerCase()))!;
};

export const getTags = (): string[] => {
	return posts
		.map((post) => post.metadata.tags)
		.flat()
		.filter((value, index, self) => self.indexOf(value) === index);
};

export const getRelatedPostsByTags = (tags: string[], limit: number | undefined = undefined): Post[] => {
	return posts
		.filter((post) => post.metadata.tags.filter((tag) => tags.includes(tag)).length > 0)
		.slice(0, limit)
		.sort(sorter);
};
