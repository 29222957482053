---
title: Migrate from Moment.js to date-fns
headline: Use date-fns as modern date and time library
tags:
  - moment.js
  - date-fns
  - migration
  - typescript
date: 2022-02-05
---

Moment.js has been a popular library for parsing, validating, manipulating, and formatting dates for a decade. However, Moment.js has too many disadvantages compared to modern date and time libraries, like date-fns.

Its API is not immutable, it is large, and it does not support tree shaking. Even the Moment team discourages to use their library in new projects. They provide several great [options](https://momentjs.com/docs/#/-project-status/recommendations/) to consider using instead of Moment.js.

When choosing, consider that:

- Some libraries are split into modules, plugins, or companion libraries.
- Some libraries use the ECMAScript Intl API for locales, time zones, or both.
- Some libraries still provide their own locale and time zone files like Moment and Moment-Timezone do.

Here are good alternatives, that Moment.js team recommends: [Luxon](https://moment.github.io/luxon/), [Day.js](https://day.js.org/), [date-fns](https://date-fns.org/), [js-Joda](https://js-joda.github.io/js-joda/) and [JS Date Object](https://developer.mozilla.org/docs/Web/JavaScript/Reference/Global_Objects/Date)

## How to migrate from Moment.js

1. Create a date-time service that hides what library you’re using. If you decide to replace the library in the future, you’ll need to update only one file.
2. Create extensive unit tests for the service.
3. Use eslint-plugin-you-dont-need-momentjs to find all instances of Moment in your codebase and replace them using your new service.
4. Uninstall moment.js

```typescript
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import add from "date-fns/add";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInDays from "date-fns/differenceInDays";
import { zonedTimeToUtc } from "date-fns-tz";

import { DateTimeService } from "./date-time-service";
import * as Constants from "./constants";

const DATE_TIME_INSTANCE = parseISO(Constants.DATE_TIME_ISO);
const ANOTHER_DATE_TIME_INSTANCE = parseISO(Constants.ANOTHER_DATE_TIME_ISO);

export const DateFnsService: DateTimeService = class {
  static parseISO() {
    parseISO(Constants.DATE_TIME_ISO);
  }

  static formatInstance() {
    format(DATE_TIME_INSTANCE, "R-MM-dd");
  }

  static tranformInstance() {
    add(DATE_TIME_INSTANCE, { years: 2 });
  }

  static diffBetweenInstances() {
    differenceInMonths(DATE_TIME_INSTANCE, ANOTHER_DATE_TIME_INSTANCE);
  }

  static compareInstances() {
    return differenceInDays(DATE_TIME_INSTANCE, ANOTHER_DATE_TIME_INSTANCE) < 0;
  }

  static timezone() {
    zonedTimeToUtc(Constants.DATE_TIME_ISO, Constants.TIMEZONE);
  }

  static setGlobalLocale() {
    // Not supported
    // https://date-fns.org/v2.16.1/docs/I18n
  }
};
```

## Final words

I use the date-fns library in my hobby projects, and it is the most powerful date library, that I have ever seen.
