<script lang="ts">
	import type { Post } from '$lib/posts';
	import PostCard from '$lib/components/post-card.svelte';

	export let posts: Post[];
</script>

<ul>
	{#each posts as post}
		<li class="pt-2 mb-4 pb-4 border-b last:border-none border-neutral-200 dark:border-neutral-800">
			<PostCard {post} />
		</li>
	{/each}
</ul>
