---
title: Run Jenkins in Docker image and use host Docker process
headline: Effortlessly run Jenkins in Docker while leveraging the host's Docker capabilities
tags:
  - jenkins
  - docker
  - alpine
  - devops
date: 2022-05-08
---

I create a custom Dockerfile to run Jenkins with plugins.

```dockerfile
FROM --platform=$BUILDPLATFORM jenkins/jenkins:2.426.1-lts-jdk17

USER root

RUN apt-get update && apt-get install -y lsb-release
RUN curl -fsSLo /usr/share/keyrings/docker-archive-keyring.asc \
  https://download.docker.com/linux/debian/gpg
RUN echo "deb [arch=$(dpkg --print-architecture) \
  signed-by=/usr/share/keyrings/docker-archive-keyring.asc] \
  https://download.docker.com/linux/debian \
  $(lsb_release -cs) stable" > /etc/apt/sources.list.d/docker.list
RUN apt-get update && apt-get install -y docker-ce-cli

RUN groupadd docker
RUN usermod -aG docker jenkins
RUN newgrp docker

RUN docker buildx create --name multibuilder --use

USER jenkins

# RUN jenkins-plugin-cli --plugins "blueocean:1.27.9 docker-workflow:1.29"
```

You can build the image with the following command:

```bash
docker build . --tag myregistry/jenkins:latest
```

To use the host Docker daemon within the Jenkins container, you will need to bind-mount the Docker socket from the host machine to the Jenkins container.

```yaml
version: "3.8"
services:
  jenkins:
    container_name: jenkins
    image: myregistry/jenkins:latest
    volumes:
      - jenkins_home:/var/jenkins_home
      - /var/run/docker.sock:/var/run/docker.sock
      - /usr/bin/docker:/usr/bin/docker
    environment:
      - JAVA_OPTS=-Dhudson.footerURL=https://jenkins.example.com
    networks:
      - default_net
    ports:
      - 8080:8080
    deploy:
      replicas: 1
      restart_policy:
        condition: on-failure
    healthcheck:
      test: ["CMD-SHELL", "sh", "-c", "curl -v https://jenkins.example.com/login || exit 1"]
      interval: 1m
      timeout: 10s
      retries: 3
      start_period: 60s
```

On the host machine want to set permissions for docker.sock and docker binary.

```bash
sudo chmod 777 /var/run/docker.sock
sudo chmod 777 /usr/bin/docker
```

You will be able to use the docker command within the Jenkins container to control the Docker daemon running on the host machine.
