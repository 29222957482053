---
title: Multi-platform Docker image build on aarch64
headline: Multi-stage and multi-platform Dockerfile is awesome
tags:
  - docker
  - devops
  - aarch64
  - arm64v8
  - amd64
  - golang
date: 2022-01-07
---

I use [Jenkins](https://www.jenkins.io) CI/CD platform for my hobby projects on aarch64 (linux/arm64v8) architecture. But I run these images on linux/amd64 at the most time. So, I need to use multi-stage Dockerfile to build image for multi-platform. After some Google search and check results, I found a great blog post about this topic on the [Docker](https://www.docker.com) website. The title of the article is [Faster Multi-Platform Builds: Dockerfile Cross-Compilation Guide](https://www.docker.com/blog/faster-multi-platform-builds-dockerfile-cross-compilation-guide/).

First of all, you can check on your OS and Docker environment, how to setup correctly the docker buildx, please see this [builder toolkit](https://docs.docker.com/buildx/working-with-buildx/) page.

`docker buildx ls`

You can access the following build ARGs in your Dockerfile:

```
BUILDPLATFORM — matches the current machine. (e.g. linux/amd64)
BUILDOS — os component of BUILDPLATFORM, e.g. linux
BUILDARCH — e.g. amd64, arm64, riscv64
BUILDVARIANT — used to set ARM variant, e.g. v7
TARGETPLATFORM — The value set with --platform flag on build
TARGETOS - OS component from --platform, e.g. linux
TARGETARCH - Architecture from --platform, e.g. arm64
TARGETVARIANT
```

Here is the minimal setup for Dockerfile to test on your system.

```dockerfile
FROM --platform=$BUILDPLATFORM golang:alpine AS build
ARG TARGETPLATFORM
ARG BUILDPLATFORM
RUN echo "I am running on $BUILDPLATFORM, building for $TARGETPLATFORM" > /log
FROM alpine
COPY --from=build /log /log
```

After you log in Docker or private registry, run this command:
`docker buildx build --platform linux/amd64,linux/arm64 --push .`

Unfortunately, we can store multi-platform images only in registry.

## Build multi-stage Dockerfile for golang app

```dockerfile
FROM --platform=$BUILDPLATFORM golang:1.17-alpine AS builder
WORKDIR /src
ARG TARGETOS TARGETARCH
RUN --mount=target=. \
    --mount=type=cache,target=/root/.cache/go-build \
    --mount=type=cache,target=/go/pkg \
    GOOS=$TARGETOS GOARCH=$TARGETARCH go build -o /out/myapp .

FROM alpine as runner
COPY --from=builder /out/myapp /bin

EXPOSE 8080

CMD ["myapp"]
```

## Final words

If the programming languages and the native libraries don't support cross-compilation, then there are still plenty of problems with it and the working solution isn't simple.
