---
title: Dockerfile for Docusaurus
headline: Serve static documentation Docusaurus website via Nginx
tags:
  - docker
  - nginx
  - devops
  - docusaurus
date: 2021-10-18
---

My first encounter with Docusaurus was in 2020! Although I had encountered it before in all kinds of code documentation environments, I did not know that those websites were made with this tool.

In the first round, I tried with my own micro frontend framework [SUI-JS](https://sui-js.siposdani87.com) how I can create a documentation website using static markdown articles from the documented code.

## Nginx - default.conf

```text title="default.conf"
server {
    listen       80;
    listen  [::]:80;
    server_name  localhost;

    #access_log  /var/log/nginx/host.access.log  main;

    root /usr/share/nginx/html;

    location / {
        try_files $uri $uri.html /index.html =404;
    }

    # redirect server error pages to the static page /50x.html
    error_page   500 502 503 504  /50x.html;
    location = /50x.html {
        root   /usr/share/nginx/html;
    }
}
```

## Dockerfile

```dockerfile title="Dockerfile"
FROM node:16 as builder

RUN npm install -g npm@8.18.0

# Install dependencies
WORKDIR /app
COPY package.json package-lock.json ./
RUN npm ci

# Copy all local files into the image
COPY . .

RUN npm run build

FROM nginx:1.23-alpine as runner

ENV INSTALL_PATH /usr/share/nginx/html

WORKDIR $INSTALL_PATH

COPY default.conf /etc/nginx/conf.d
COPY --from=builder /app/build/ $INSTALL_PATH/

RUN set -x ; \
  addgroup -g 82 -S www-data ; \
  adduser -u 82 -D -S -G www-data www-data && exit 0 ; exit 1

RUN chown -R www-data:www-data $INSTALL_PATH/*
RUN chmod -R 0755 $INSTALL_PATH/*

```

## Conclusion

Finally, It is a simple solution to have a part of a CI/CD process. Docker build produce a small-sized nginx image.
