<script lang="ts">
	import type { Post } from '$lib/posts';
	import Tags from '$lib/components/tags.svelte';
	import Time from '$lib/components/time.svelte';

	export let post: Post;
</script>

<div>
	<h2>
		<a
			data-sveltekit-reload
			href={post.path}
			class="underline underline-offset-1 decoration-1 decoration-primary hover:text-primary"
			>{post.metadata.title}</a
		><br />
	</h2>
	<span class="text-sm">{post.metadata.headline ?? ''}</span>
	<div class="mt-1 mb-1">
		<Time datetime={post.metadata.date} />
	</div>
	<Tags tags={post.metadata.tags} />
</div>
