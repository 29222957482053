---
title: How to make Rails response faster
headline: Boost your Rails performance with Brotli compression
tags:
  - rails
  - brotli
  - ruby
date: 2022-03-22
doc: https://onlyoneaman.medium.com/how-to-make-rails-response-faster-a8cc5f1242d
---

The simpliest way to gain your app response performance is compress the data with (brotli)[https://en.wikipedia.org/wiki/Brotli]

To make your Rails response faster with Brotli, you can follow these steps:

Install the rack-deflater gem:

```bash
gem install rack-deflater
```

Add the rack-deflater gem to your Gemfile and run bundle install:

```gemfile
gem 'rack-deflater'
```

In your config/application.rb file, add the following line to enable the middleware:

```ruby
config.middleware.use Rack::Deflater
```

In your config/environments/production.rb file, add the following line to set the Content-Encoding header to br:

```ruby
config.middleware.insert_before Rack::Deflater, Rack::Brotli
```

Precompile your assets by running the following command:

```bash
RAILS_ENV=production rake assets:precompile
```

This will compress your assets using Brotli and serve them with the Content-Encoding: br header, which will allow browsers that support Brotli to decompress the response and improve the loading time of your website.
