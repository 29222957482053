---
title: Dockerfile for Angular SSR
headline: Build and Run an Angular SSR App with Docker
tags:
  - docker
  - node.js
  - devops
  - angular
date: 2023-12-13
---

Angular Universal was the old technique that allows you to render your Angular app on the server side, instead of the browser. Angular SSR is the new server side rendering solution. This can improve the performance, SEO, and user experience of your app.

## Create scripts in package.json

Create **build:ssr** command to builds the Angular app for server-side rendering (SSR) using the production configuration. The **serve:ssr** command runs the server-side app using Node.js. Your project name must be changed in the serve script. These two scripts will be used in Dockerfile.

```json title="package.json"
{
    ...
    "scripts": {
        ...
        "build:ssr": "ng build --configuration production",
        "serve:ssr": "node dist/**yourprojectname**/server/server.mjs",
        ...
    }
    ...
}
```

## Dockerfile

```dockerfile title="Dockerfile"
FROM --platform=$BUILDPLATFORM node:20.10-alpine AS builder

ENV INSTALL_PATH /app
RUN mkdir -p $INSTALL_PATH
WORKDIR $INSTALL_PATH
COPY ./package.json ./package-lock.json $INSTALL_PATH/
RUN apk add --update --no-cache python3 make g++
RUN npm install
COPY . $INSTALL_PATH
RUN npm run build:ssr

FROM node:20.10-alpine AS runner

ENV NODE_ENV production
ENV PORT 3000

ENV INSTALL_PATH /app
RUN mkdir -p $INSTALL_PATH
WORKDIR $INSTALL_PATH
# COPY --from=builder $INSTALL_PATH/package.json $INSTALL_PATH/package-lock.json $INSTALL_PATH/
# RUN npm install --production
COPY --from=builder $INSTALL_PATH/dist $INSTALL_PATH/dist

EXPOSE 3000

CMD ["npm", "run", "serve:ssr"]
```

## Conclusion

Finally, It is a simple solution to have a part of a CI/CD process. Docker build produce a small-sized alpine image.
