---
title: Custom error pages with assets for Nginx web server
headline: How to make your Nginx error pages more user-friendly with assets
tags:
  - nginx
  - html
  - css
date: 2023-05-19
---

## Custom error pages (404 and 50x) with assets (images and styles)

If you want to use custom error pages via Nginx server, follow my instruction. It's not as simple as it sounds, there are a lot of little things you have to deal with to make it work properly. I use different type of assets as favicon images, fonts and external stylesheet file on this error pages. So, in this way, you can create error pages with a completely unique appearance for the Nginx server.

### custom_404.html

```html title="custom_404.html"
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>404: Not Found</title>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
    <link rel="stylesheet" href="style.css">
    <link rel="icon" type="image/x-icon" href="favicon.ico">
</head>
<body>
    <div class="container">
        <span class="material-symbols-outlined icon">
            error
        </span>
        <h1>Oops! Page not found...</h1>
        <p>I have no idea, where that file is. Are you sure you typed in the correct URL?</p>
    </div>
</body>
</html>
```

### style.css

```css title="style.css"
@import url('https://fonts.googleapis.com/css2?family=Lunasima:wght@400;700&display=swap');

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-size: 18px;
    font-family: 'Lunasima', sans-serif;
    font-weight: 400;
    margin: 0;
}

.container {
    padding: 2em;
    background-color: gray;
    border-radius: 1em;
    margin: 1em;
}

.icon {
    font-size: 3em;
    color: blue;
}

h1 {
    font-size: 2em;
    font-weight: 700;
    margin-top: 0.3em;
}

p {
    margin-bottom: 1.5em;
}

@media only screen and (min-width: 768px) {
    .container {
        max-width: 50vw;
    }
}
```

### default.conf

Here is the **default.conf** file for Nginx server. Custom error pages (e.g.: 404 and 50x) work with icon image and CSS style assets.

The **/testing** location will be show your 500 error from your Nginx server.

```conf title="default.conf"
server {
    listen 80 default_server;
    listen [::]:80 default_server ipv6only=on;

    error_page 404 = @error_404;
    location @error_404 {
        root /usr/share/nginx/html;
        try_files $uri /custom_404.html =404;
        internal;
    }

    error_page 500 502 503 504 = @error_50x;
    location @error_50x {
        root /usr/share/nginx/html;
        try_files $uri /custom_50x.html =404;
        internal;
    }

    location /testing {
        fastcgi_pass unix:/does/not/exist;
    }
}
```

## Conclusion

Finally, It is a simple solution to have custom error HTML files for nginx server.
