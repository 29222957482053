---
title: Setup docker-compose for external volume location
headline: Manage volume data in Docker
tags:
  - redis
  - docker
  - devops
date: 2021-11-06
---

[Manage Data in Docker](https://docs.docker.com/storage/) to use external volume. Your volumes are stored in a part of the host file system which is managed by Docker (/var/lib/docker/volumes/ on Linux). The description of bind mounts was not very clear to me and I had to use other sources.

## Example of docker-compose.yml

I use [Redis](https://redis.io/) as a cache storage on some projects. Sometimes it is necessary to store the data on an external directory or a mounted volume, so I would show the details below as an example.

```yaml title="docker-compose.yml"
version: '3.7'
services:
  redis:
  container_name: redis
  image: redis:5.0.14-alpine
  command: ['redis-server', '--appendonly', 'yes']
  volumes:
    - redis_data:/data
  networks:
    - redis_net
  deploy:
    replicas: 1
    restart_policy:
      condition: on-failure
  healthcheck:
    test: ['CMD-SHELL', 'redis-cli', 'ping']
    interval: 1m
    timeout: 10s
    retries: 3
    start_period: 60s
networks:
  redis_net:
volumes:
  redis_data:
    driver: local
    driver_opts:
      type: none
      o: bind
      device: '/mnt/volume_01/docker_redis_data'
```

## Conclusion

When I raised the issue, I thought of a simpler configuration setting, but in the end, it didn't.
