<script lang="ts">
	import Tag from '$lib/components/tag.svelte';
	export let tags: string[];

	const sortTags = (tags: string[]) => {
		return tags.sort();
	};
</script>

<nav>
	{#each sortTags(tags) as tag}
		<Tag {tag} />
	{/each}
</nav>
