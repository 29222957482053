---
title: Use render and unmount on React and SolidJS
headline: Define these functions to mount components
tags:
  - react
  - solid.js
  - typescript
date: 2022-10-23
---

The both library, React and SolidJS are the most powerful component renders at this time. I like to work both of them! Sometimes it is necessary to use native render and unmount methods in typescript, if you don't use these as a framework, only as a library.

So I show you, how you use these component renderers in the browser environment.

## React

```typescript title="reactUtils.ts"
import { ComponentClass, createElement, FunctionComponent } from 'react';
import * as ReactDOM from 'react-dom/client';

export const renderReact = (
    type: FunctionComponent | ComponentClass | string,
    props: Object,
    mountNode: HTMLElement,
): ReactDOM.Root => {
    const root = ReactDOM.createRoot(mountNode);
    root.render(createElement(type, props));

    return root;
};

export const unmountReact = (reactRoot: ReactDOM.Root): void => {
    reactRoot.unmount();
};
```

## SolidJS

```typescript title="solidUtils.ts"
import { render } from 'solid-js/web';
import { Component } from 'solid-js';

export type Dispose = () => void;

export const renderSolid = <P>(component: Component<P>, props: P, mountNode: HTMLElement): Dispose => {
    return render(() => component.call(this, props), mountNode);
}

export const unmountSolid = (dispose: Dispose): void => {
    dispose();
};
```

### Last words

These utility methods help ours to make my coding life easier! Let's use it!
