<script lang="ts">
	import Tags from '$lib/components/tags.svelte';
	import Time from '$lib/components/time.svelte';
	import { siteBaseUrl } from '$lib/meta';

	export let title;
	export let headline;
	export let tags;
	export let date;
</script>

<svelte:head>
	<title>{title}</title>

	<meta name="description" content={headline} />
	<meta name="keywords" content={tags.join(', ')} />
</svelte:head>

<article class="prose lg:prose-xl prose-neutral dark:prose-invert prose-a:text-primary">
	<header class="mb-4 pb-4 border-b-2 border-neutral-200 dark:border-neutral-800">
		<h1>{title}</h1>
		<small>{headline ?? ''}</small>

		<Tags {tags} />
		<address>
			By: <a href={siteBaseUrl} rel="noreferrer" target="_blank">Dániel Sipos</a> on <Time
				datetime={date}
			/>
		</address>
	</header>

	<slot />
</article>

<style lang="postcss">
	.prose {
		@apply max-w-full;
	}
</style>
