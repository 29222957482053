---
title: Use webpack for NestJS builder to create one file build
headline: 
tags:
  - webpack
  - nestjs
date: 2024-07-10
---

NestJS is a powerful, flexible framework for building scalable server-side applications. By default, NestJS uses its own build system, but for more control over the build process, you can use Webpack. Webpack can bundle your application into a single file, which simplifies deployment and can improve performance.

## Configuring NestJS to Use Webpack

Update your package.json to use the Webpack builder:

```json title="package.json"
  ...
  "scripts": {
    "build": "nest build --builder=webpack",
    ...
  }
  ...
```

## TypeScript Configuration

Ensure your tsconfig.json is configured correctly for the build:

```json title="tsconfig.json"
{
  "compilerOptions": {
    "module": "CommonJS",
    "resolveJsonModule": true,
    "declaration": true,
    "removeComments": true,
    "emitDecoratorMetadata": true,
    "experimentalDecorators": true,
    "allowSyntheticDefaultImports": true,
    "target": "ES2021",
    "sourceMap": true,
    "outDir": "./dist",
    "baseUrl": "./",
    "incremental": true,
    "skipLibCheck": true,
    "strictNullChecks": false,
    "noImplicitAny": false,
    "strictBindCallApply": false,
    "forceConsistentCasingInFileNames": false,
    "noFallthroughCasesInSwitch": false,
  }
}
```

This configuration ensures compatibility with the latest JavaScript features and provides robust type-checking and compilation settings.

## Build Script

Create a build.sh script to automate the build and prepare the deployment package:

```sh title="build.sh"
npm i
npm run build
cp package.json ./dist/package.json
cp package-lock.json ./dist/package-lock.json
npm install --prefix ./dist --omit=dev

# cp .env ./dist/.env
```

This script:

1. Installs project dependencies.
2. Builds the project using Webpack.
3. Copies package.json and package-lock.json to the dist directory.
4. Installs production dependencies in the dist directory.
5. (Optional) Copies the .env file to the dist directory if your project uses environment variables.

## Running the Build

To execute the build script, make it executable and run it:

```bash
chmod +x build.sh
./build.sh
```

This will create a dist directory containing bundle.js and the necessary dependencies for your application.

## Conclusion

Using Webpack with NestJS provides a streamlined build process, resulting in a single file that simplifies deployment and can enhance performance. By following this guide, you can leverage Webpack’s powerful bundling capabilities to create an efficient and maintainable build system for your NestJS application.
