---
title: Use Apache .htaccess file for Next.js static outputs
headline: How to use .htaccess file to serve Next.js static files on Apache server
tags:
  - next.js
  - apache
  - devops
  - webpack
date: 2023-07-22
---

I have a static website from Next.js. I want to serve it to the public internet via Apache web server.

Here is my config file of Next.js, how it generates static output from the project.

```javascript title="next.config.js"
const path = require('path');
const withPlugins = require('next-compose-plugins');
const { webpack } = require('./next-webpack.config');
const withPWA = require('next-pwa');

module.exports = withPlugins([
  [withPWA, {
    pwa: {
      disable: process.env.NODE_ENV === 'development',
      dest: 'public'
    }
  }],
], {
  basePath: '',
  reactStrictMode: true,
  webpack5: true,
  poweredByHeader: false,
  trailingSlash: true,
  pageExtensions: ['ts', 'tsx'],
  sassOptions: {
    includePaths: [path.join(__dirname, 'styles')],
  },
  images: {
    disableStaticImages: true,
    domains: [],
    formats: ['image/avif', 'image/webp']
  },
  webpack,
});
```

The Apache server reads .htaccess file from the host directory and process the redirect rules by conditions.

In this file you see many redirections.

0. error document handled by /404 path
1. all request, without existing files redirect to root index.html file
2. all request serves by https protocol
3. www subdomain will be handled by main domain
4. second domain redirect to main domain
5. third domain redirect to subpath of main domain

```text title=".htaccess"
RewriteEngine On

ErrorDocument 404 https://example.com/404/

RewriteRule ^([^/]+)/$ $1.html

RewriteCond %{REQUEST_FILENAME} !-f
RewriteRule ^([^/]+)/$ $1.html
RewriteRule ^([^/]+)/([^/]+)/$ /$1/$2.html

RewriteCond %{SERVER_PORT} 443
RewriteCond %{REQUEST_FILENAME} !-f
RewriteCond %{REQUEST_FILENAME} !-d
RewriteCond %{REQUEST_URI} !(\.[a-zA-Z0-9]{1,5}|/)$
RewriteRule ^(.*)$ https://example.com/$1/ [R=301,L]

RewriteCond %{HTTP_HOST} ^www.example.com$
RewriteCond %{SERVER_PORT} 443
RewriteRule ^(.*)$ https://example.com/$1 [R,L]

RewriteCond %{HTTP_HOST} ^example\.com$ [OR]
RewriteCond %{HTTP_HOST} ^www.example.com$
RewriteCond %{SERVER_PORT} 80
RewriteRule ^(.*)$ https://example.com/$1 [R,L]

RewriteCond %{HTTP_HOST} ^example2\.com$ [OR]
RewriteCond %{HTTP_HOST} ^www.example2.com$
RewriteRule ^(.*)$ https://example.com/$1 [R,L]

RewriteCond %{HTTP_HOST} ^example3\.com$ [OR]
RewriteCond %{HTTP_HOST} ^www.example3.com$
RewriteRule ^(.*)$ https://example.com/example3/ [R,L]
```

### Final words

Most needs are covered by these types of redirection rules and the options provided by the .htaccess file on the Apache web server are good.
