---
title: Setup Cypress coverage on typescript Next.js 12
headline: Create coverage test results
tags:
  - cypress
  - next.js
  - testing
date: 2022-09-12
---

To set up Cypress for a Next.js project, you can follow these steps:

Install Cypress using npm:

```bash
npm install --save-dev cypress
```

Initialize Cypress by running the following command:

```bash
npx cypress init
```

Modify the package.json file to add a script for running Cypress tests:

```json
"scripts": {
  "cypress:open": "cypress open",
  "cypress:run": "cypress run"
},
```

In your Next.js server.js file, add the following code to start the server in development mode when running Cypress tests:

```javascript
if (process.env.CYPRESS_ENV === 'development') {
  require('next/dist/server/next')().start(3000)
}
```

Create a test file in the cypress/integration directory. For example, you could create a file called test.js with the following content:

```javascript
describe('My test', () => {
  it('visits the homepage', () => {
    cy.visit('http://localhost:3000')
  })
})
```

Run the Cypress test runner using the following command:

```bash
npm run cypress:open
```

This will open the Cypress test runner, where you can view and run your tests.
