<script lang="ts">
	export let datetime: string;
</script>

<time {datetime}>{new Date(datetime).toLocaleDateString()}</time>

<style lang="postcss">
	time {
		@apply text-slate-500;
	}
</style>
