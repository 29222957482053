---
title: SendGrid email forwarding hook on free plan
headline: Cost-effective solution to handle inbound emails
tags:
  - node.js
  - sendgrid
  - backend
date: 2021-09-24
---

I love the cost-effective solutions for the development. When I tried the email sending options provided by SendGrid, I found that the part of receiving email is included in the paid plans. So, using the [SendGrid](https://sendgrid.com) API with webhook want to forward the incoming emails to my favorite email account.

## Create /api/sendgrid/mail-forwarding endpoint

Choose your best `node.js` framework (e.g.: express, next.js, nuxt.js, nest.js, ...) and create a new endpoint with the name you want.

First, it was necessary to install a few packages ([@sendgrid/mail](https://www.npmjs.com/package/@sendgrid/mail), [multer](https://www.npmjs.com/package/multer)), that is, to add them to the project.

```bash
npm i @sendgrid/mail multer --save
```

Here is the TS code snippet, that I use to forward incomming emails to my personal email account.

```typescript
// /sendgrid/mail-forwarding.ts
import sendGridMail from '@sendgrid/mail';
import multer from 'multer';

const SENDGRID_API_KEY = 'API_KEY'; // Use your own SendGrid api_key
const SENDGRID_TO_EMAIL = 'to@example.com'; // Use your personal email address to receive mails
const SENDGRID_FROM_EMAIL = 'from@example.com', // Use the email address or domain you verified on SendGrid

const initMiddleware = (middleware: any): => {
    return (req: Request, res: Response) =>
      new Promise((resolve, reject) => {
        middleware(req, res, (result: any) => {
            if (result instanceof Error) {
                return reject(result);
            }
            return resolve(result);
        });
    });
};

sendGridMail.setApiKey(SENDGRID_API_KEY!);

type ResponseData = {}

const upload = multer();
const multerAny = initMiddleware(
  upload.any(), // Note that Multer limits to 1MB file size by default
);

export default async (req: Request, res: Response<ResponseData>) => {
  if (req.method !== 'POST') {
    return res.status(405).json({});
  }

  await multerAny(req, res);

  const body = req.body;
  if (!body['text'] && !body['html']) {
    return res.status(400).json({});
  }

  const mailData: sendGridMail.MailDataRequired = {
    to: SENDGRID_TO_EMAIL,
    from: SENDGRID_FROM_EMAIL!,
    replyTo: body['from'],
    subject: body['subject'] ?? 'Forwarded email',
    text: body['text'],
    html: body['html'],
  };

  try {
    await sendGridMail.send(mailData);
  } catch (_error: any) {
    return res.status(500).json({});
  }

  res.status(200).json({});
}
```

## SendGrid - Inbound Parse

Visit SendGrid / Settings page, here is the [link](https://app.sendgrid.com/settings/parse). On the screen press the `Add Host & URL` and set the following options:

- Receiving Domain: // Choose your domain
- Destination URL: // Location of your deployed API endpoint
- Additional Options
  - SPAM CHECK ✅
  - SEND RAW ❌

## Conclusion

For more than half a year now, this little email forwarding endpoint has been doing its job, I had no problem with hundreds of emails.
