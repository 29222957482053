---
title: Clean iOS app splash screen cache on React Native Expo
headline: Start your iOS app with a fresh launch screen - clean your splash screen cache with ease
tags:
  - expo
  - ios
  - react-native
date: 2023-03-15
---

You might have an issue, when you tried to change something in the splash screen on the React Native Expo app, you still see the old launch screen. Because the system caches splash images, and it's not cleared even after deleting the app.

For cleaning the splash screen, all you have to do to completely clear your app’s launch screen cache is run this code inside your app.

Create a new public Swift class that extends ExpoAppDelegateSubscriber from ExpoModulesCore and add its name to the ios.appDelegateSubscribers array in the module config.

AppLifecycleDelegate.swift

```swift title="AppLifecycleDelegate.swift"
import ExpoModulesCore
import UIKit

public extension UIApplication {
    func clearLaunchScreenCache() {
        do {
            let launchScreenPath = "\(NSHomeDirectory())/Library/SplashBoard"
            try FileManager.default.removeItem(atPath: launchScreenPath)
        } catch {
            print("Failed to delete launch screen cache - \(error)")
        }
    }
}

public class AppLifecycleDelegate: ExpoAppDelegateSubscriber {
  public func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
        // Override point for customization after application launch.
        UIApplication.shared.clearLaunchScreenCache()
        sleep(5)
        return true
    }
}
```

Expo modules are configured in expo-module.config.json. This file currently is capable of configuring autolinking and module registration.

expo-module.config.json

```json title="expo-module.config.json"
{
    "ios": {
      "appDelegateSubscribers": ["AppLifecycleDelegate"]
    }
}
```

## Final words

In custom iOS builds, launch screens can sometimes remain cached between builds, making it harder to test new images. Apple recommends clearing the derived data folder before rebuilding, this can be done with Expo CLI by running: **npx expo run:ios --no-build-cache**, but it does not work, so I created these files to resolve the cache pruning issue.
