---
title: Docusaurus for typescript source code documentation
headline: Easily create professional-quality documentation for your TypeScript code with Docusaurus
tags:
  - typescript
  - docusaurus
  - typedoc
date: 2021-11-23
---

Docusaurus is a static site generator that can be used to create documentation for your project. You can use it to document your TypeScript code by following these steps:

Install Docusaurus using npm:

```bash
npm install --save-dev docusaurus
```

Create a new Docusaurus project and follow the prompts to set up your documentation site:

```bash
npx docusaurus-init
```

Create a TypeScript configuration file (tsconfig.json) in the root directory of your project. This file specifies the compiler options for your TypeScript code.

In the docusaurus.config.js file, add the TypeScript configuration file to the presets array under the docs field. This will tell Docusaurus to use the TypeScript configuration when processing your documentation files:

```javascript
module.exports = {
  ...
  docs: {
    ...
    presets: [
      [
        '@docusaurus/preset-classic',
        {
          ...
          tsconfig: './tsconfig.json',
        },
      ],
    ],
  },
  ...
};
```

Create your documentation files in the docs directory, using the .tsx extension for TypeScript files that contain JSX. You can then reference these files in your documentation using the standard Docusaurus markdown syntax.

Run the Docusaurus build command to generate your documentation site:

```bash
npx docusaurus build
```

This will compile your TypeScript code and generate the static documentation site in the build directory.

To use the docusaurus-plugin-typedoc plugin with Docusaurus, you can follow these steps:

Install the plugin using npm:

```bash
npm install --save-dev docusaurus-plugin-typedoc
```

In your Docusaurus configuration file (docusaurus.config.js), add the plugin to the plugins array:

```javascript
module.exports = {
  ...
  plugins: ['docusaurus-plugin-typedoc'],
  ...
};
```

Create a TypeScript configuration file (tsconfig.json) in the root directory of your project. This file specifies the compiler options for your TypeScript code.

In the docusaurus.config.js file, add the TypeScript configuration file to the typedoc field under the plugins section:

```javascript
module.exports = {
  ...
  plugins: [
    [
      'docusaurus-plugin-typedoc',
      // Plugin / TypeDoc options
      {
        entryPoints: ['../src/index.ts'],
        tsconfig: './tsconfig.json',
      },
    ],
  ],
  ...
};
```

Run the Docusaurus build command to generate your documentation site:

```bash
npx docusaurus build
```

This will process your TypeScript code using TypeDoc and generate documentation for it, which will be included in your documentation site.
