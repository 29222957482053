<script lang="ts">
	export let tag: string;
</script>

<a data-sveltekit-reload href={`/tags/${tag}`} class="tag">#{tag}</a>

<style lang="postcss">
	.tag {
		@apply inline-block px-3 py-1 mr-2 mb-2 border border-slate-500 rounded-lg text-primary font-bold text-sm no-underline;
	}

	.tag:hover {
		@apply border-slate-600 bg-primary text-white;
	}
</style>
