---
title: Flutter dev environment for all platforms on Apple MacBook M1 ARM
headline: Learn how to use Flutter to build cross-platform apps on an Apple M1 Mac
tags:
  - flutter
  - vmware
  - dev
  - arm64v8
date: 2023-12-15
---

In this article, I want to write about how to create a cross-platform runtime environment and deliver a good solution for **Flutter application development**.

First, I would like to point out that, unfortunately, it can only be implemented on **Apple Mac M1** computers, since the macOS and iOS platforms are only available on these machines... 😥

Which platforms exactly are we talking about, i.e. **Android, iOS, macOS, Web, Windows and Linux**. The first 4 platforms work fine on a Mac ARM computer, but Windows and Linux are only available on that computer in a virtualized environment. I tried a lot of options and programs (UTM Virtual Machines, Parallels, VirtualBox, etc...), most of them belong to the paid category, but **VMware Fusion** can deliver a free solution for this, with a good performance.

## VMware Fusion

Harness the full power of your Mac when you use VMware Fusion to run Windows, Linux, containers, Kubernetes and more in virtual machines (VMs) without rebooting.

Try and use with registration for free: https://www.vmware.com/products/fusion.html

## Windows 11 ARM

To access and download this Windows ARM ISO, you need to be a member of the Windows Insider program. Follow this link, for more information: https://www.microsoft.com/en-us/software-download/windowsinsiderpreviewARM64

Microsoft is working to deliver an Arm-native developer toolset that includes Arm-native Visual Studio 2022, Visual Studio Code, VC++ toolchain, classic .NET Framework, modern .NET, and Java. Microsoft is also working with several 3rd parties and open-source communities to port common tools, runtimes, frameworks and libraries to natively target Windows on Arm

Follow the Flutter documentation about Windows Dev Env: https://docs.flutter.dev/get-started/install/windows

## Linux Ubuntu 22.04.x ARM

The ARM version of Ubuntu 22.04 Desktop is a bit hidden from the public, but you can still find it in the official ISO files.
Download the arm64v8 version from it: https://cdimage.ubuntu.com/jammy/daily-live/current/

Linux machines with ARM-based CPUs aren't currently supported by Android Studio. So you can use Visual Studio Code as Flutter IDE.

Follow the Flutter documentation about Linux Dev Env: https://docs.flutter.dev/get-started/install/linux

## Conclusion

I'm glad I found a free solution for building a Flutter development environment for all supported platforms.
