---
title: Reduce Docker image size significantly of Next.js
headline: Shrink image size by 200+ MB on a medium-sized SSR app
tags:
  - next.js
  - docker
  - devops
date: 2021-08-04
---

Next.js is a powerful React framework. I like working with it, because it makes the developer’s work easier and can only focus on developing important business values. However, even for a medium-sized application, a large Docker image is generated by a Dockerfile, that I downloaded it from the official Next.js site.

## Original Next.js Dockerfile

You can see this [Dockerfile](https://github.com/vercel/next.js/blob/main/examples/with-docker/Dockerfile) on (Vencel/next.js) GitHub repository.
The framework officially favors the `yarn` package manager over `npm`. But I tried several optimization options and finally changed to `npm`.

## Modified Dockerfile

```dockerfile
# Install dependencies only when needed
FROM node:14-alpine AS deps
# Check https://github.com/nodejs/docker-node/tree/b4117f9333da4138b03a546ec926ef50a31506c3#nodealpine to understand why libc6-compat might be needed.
RUN apk add --no-cache libc6-compat
WORKDIR /app
COPY package.json package-lock.json ./
RUN npm install

# Rebuild the source code only when needed
FROM node:14-alpine AS builder
WORKDIR /app

COPY . .
COPY --from=deps /app/node_modules ./node_modules
RUN npm run build
RUN npm prune --production
RUN npm install --production

# Production image, copy all the files and run next
FROM node:14-alpine AS runner
WORKDIR /app

ENV NODE_ENV production

RUN addgroup -g 1001 -S nodejs
RUN adduser -S nextjs -u 1001

# You only need to copy next.config.js if you are NOT using the default configuration
COPY --from=builder /app/.env.local ./
COPY --from=builder /app/next-i18next.config.js ./
COPY --from=builder /app/next-webpack.config.js ./
COPY --from=builder /app/next.config.js ./
COPY --from=builder /app/postcss.config.js ./
COPY --from=builder /app/tailwind.config.js ./
COPY --from=builder /app/public ./public
COPY --from=builder --chown=nextjs:nodejs /app/.next ./.next
COPY --from=builder /app/node_modules ./node_modules
COPY --from=builder /app/package.json ./package.json

USER nextjs

EXPOSE 3000

ENV PORT 3000

# Next.js collects completely anonymous telemetry data about general usage.
# Learn more here: https://nextjs.org/telemetry
# Uncomment the following line in case you want to disable telemetry.
#ENV NEXT_TELEMETRY_DISABLED 1

CMD ["npm", "run", "start"]
```

## Conclusion

In the end, with this modification, the initial image size of almost ~800 MB was **reduced to ~600 MB**, which is still huge, but this framework has a lot of dependency packages. I have not experienced any build and runtime errors with this modification, so I use it on my [I18Nature](https://i18nature.com) SSR landing page.
